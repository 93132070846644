html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: "Noto Sans HK", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 21cm;
  height: 29.4cm;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem;
}
